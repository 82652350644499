import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import LoadingSuspense from '@mp/mp-loading-mask-suspense';
import AuthServices from '@mp/mp-auth-services';

const Root = () => {

    const [token, setToken] = React.useState(AuthServices.getWithExpiry('token'));

    const changeAuth = (e) => {
        setToken(e.detail.token)
    }

    const resetToken = () => {
        setToken(null);
    }

    React.useEffect(() => {
        window.addEventListener("@mp/app-login/auth", changeAuth);
        return () => window.removeEventListener("@mp/app-login/auth", changeAuth);
    });

    React.useEffect(() => {
        window.addEventListener("@mp/app-login/logout", resetToken);
        return () => window.removeEventListener("@mp/app-login/logout", resetToken);
    });

    const Error = ()  => {
        return (
            <div>
                <h1>Error 404</h1>
                <h1>Not Found</h1>
            </div>
        )
    }

    return (
        <LoadingSuspense>   
            <Router>
                <Switch>
                    <Redirect from="/" exact={true} to="/login" />
                    <Route path="/login" render={(props) => (token ? <Redirect from="/login" exact={true} to="/erp" /> : <React.Fragment/>)} />
                    <Route path='/erp' />
                    <Route path="*" exact={true} component={Error}/>
                </Switch>
            </Router>
        </LoadingSuspense> 
    )
}

export default Root;